.Toast__toast___XXr3v {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  padding: 16px;
  background-color: var(--lightSquidInk);
  font-size: 14px;
  color: #fff;
  box-sizing: border-box;
}

.Toast__toast___XXr3v > span {
  margin-right: 10px;
}

.Toast__toastClose___18lU4 {
  margin-left: auto;
  align-self: center;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  cursor: pointer;
}

.Toast__toastClose___18lU4::before,
.Toast__toastClose___18lU4::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: var(--lightGrey);
}

.Toast__toastClose___18lU4:hover::before,
.Toast__toastClose___18lU4:hover::after {
  background: var(--red);
}

.Toast__toastClose___18lU4::before {
  transform: rotate(45deg);
}
.Toast__toastClose___18lU4::after {
  transform: rotate(-45deg);
}