.SelectInput__selectInput___3efO4 {
	display: flex;
}

.SelectInput__selectInput___3efO4 > input {
	flex: 1;
	border-radius: 0 3px 3px 0 !important;
}

.SelectInput__selectInput___3efO4 > select {
	padding: 16px 20px 16px 16px;
	font-size: 14px;
	color: var(--deepSquidInk);
	background-color: #fff;
	background-image: none;
	border: 1px solid var(--lightGrey);
	border-right: none;
	border-radius: 3px 0 0 3px;
	box-sizing: border-box;
	margin-bottom: 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	flex-basis: 22%;
	width: 1%;

	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%),
		linear-gradient(to right, #ccc, #ccc);
	background-position: calc(100% - 10px) calc(1em + 8px),
		calc(100% - 4px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
	background-size: 6px 5px, 6px 5px, 0px 1.5em;
	background-repeat: no-repeat;
}
